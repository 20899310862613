import { Box, Input, Textarea, Typography } from "@mui/joy";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { useLocation } from "react-router-dom";

import handleContact from "../../handlers/ses-handler";

import { useEffect, useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("senden");

  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView();
      }
    }

    window.history.scrollRestoration = "manual";
  }, [location]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setButtonText("senden...");
    const data = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    handleContact(data).then(() => {
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setLoading(false);
      setButtonText("senden");
    });
  };

  return (
    <Box
      className="w-full flex justify-center items-center gap-8 py-8"
      sx={{
        backgroundColor: "white",
        marginTop: { xs: "1rem", md: "3rem" },
      }}
      id="contact"
    >
      <Box
        className="flex flex-col w-full"
        sx={{ maxWidth: "var(--content-width)", px: { xs: 4, xl: 0 } }}
      >
        <Box className="flex flex-grow gap-4 mx-auto flex-wrap justify-center w-full mt-8">
          <Box className="flex flex-col text-center gap-2" sx={{ flexGrow: 2 }}>
            <Typography
              textColor="var(--primary-color)"
              level="h3"
              fontWeight={600}
            >
              <span className="marked">Kontakieren Sie uns</span>
            </Typography>
            <Typography
              textColor="#111821"
              level="display2"
              fontWeight="var(--font-weight-heading)"
              letterSpacing="0.1rem"
            >
              Stellen Sie uns Ihr Projekt vor!
            </Typography>
          </Box>
        </Box>
        <form className="flex flex-col gap-8 mt-16" onSubmit={onSubmit}>
          <Box className="grid grid-cols-3 gap-8">
            <Input
              sx={{
                gridColumn: { xs: "span 3", md: "span 1" },
                borderRadius: 8,
                backgroundColor: "var(--input-background-color)",
                color: "var(--input-text-color)",
                borderColor: "var(--input-border-color)",
              }}
              placeholder="Max Mustermann *"
              size="lg"
              required
              variant="plain"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              sx={{
                gridColumn: { xs: "span 3", md: "span 1" },
                borderRadius: 8,
                backgroundColor: "var(--input-background-color)",
                color: "var(--input-text-color)",
                borderColor: "var(--input-border-color)",
              }}
              placeholder="Deine E-Mail *"
              size="lg"
              required
              variant="plain"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              sx={{
                gridColumn: { xs: "span 3", md: "span 1" },
                borderRadius: 8,
                backgroundColor: "var(--input-background-color)",
                color: "var(--input-text-color)",
                borderColor: "var(--input-border-color)",
              }}
              type="tel"
              placeholder="Handynummer Bsp. +XX XXX XXXXXXX *"
              size="lg"
              required
              variant="plain"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Box>
          <Box>
            <Textarea
              sx={{
                backgroundColor: "var(--input-background-color)",
                color: "var(--input-text-color)",
                borderColor: "var(--input-border-color)",
              }}
              minRows={10}
              maxRows={10}
              placeholder="Frage *"
              size="lg"
              variant="plain"
              required
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
          <Box className="flex justify-end mb-8">
            <LoadingButton
              loading={loading}
              type="submit"
              className="button--round"
              startIcon={<SendIcon />}
              sx={{
                fontWeight: "500 !important",
                color: "white !important",
                backgroundColor: "var(--primary-color) !important",
                textTransform: "none !important",
              }}
            >
              {buttonText}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
