import { Box, Card, Link, Typography } from "@mui/joy";
import React from "react";

const projects = [
  {
    title: "ParaBook",
    shortDescription:
      "ParaBook ist eine Softwarelösung, die in enger Zusammenarbeit mit der Flugschule Wildschönau entwickelt wurde. Sie ermöglicht es der Flugschule, die Verwaltung von Tandemflügen effizient zu gestalten. Piloten und Personal haben Zugriff auf eine benutzerfreundliche Plattform, auf der sie Flüge einsehen und verwalten können. Parabook bietet zudem umfangreiche Statistiken und Saisonberichte, um einen umfassenden Überblick über die Flugaktivitäten zu erhalten. Sie verfügt über ein Buchungssystem, sowie Abwesenheiten, Benachrichtigungen und vielem mehr.",
    imgUrl: [
      "./images/flugschule-wildschoenau.png",
      "https://parabook.at/logo/parabook/black.png",
    ],
    weblink: ["https://www.paragliding.at/", "https://site.parabook.at"],
  },
];

//create a function that returns a card with the given parameters
const galleryItem = (title, shortDescription, imgUrl, weblink) => {
  return (
    <Card
      key={title}
      className="flex items-center justify-center flex-col gap-4"
      variant="outlined"
      sx={{ width: 500, backgroundColor: "white" }}
    >
      <Box className="mt-10 flex flex-wrap sm:gap-10 justify-center gap-8">
        {imgUrl.map((url) => (
          <img key={url} src={url} className="h-28" loading="lazy" alt="" />
        ))}
      </Box>
      <Box className="flex flex-col justify-center p-5 gap-4 items-center">
        <Typography
          level="body1"
          textAlign="center"
          fontWeight="var(--font-weight-light)"
          letterSpacing={0.5}
          sx={{ mt: 2, mb: 2 }}
        >
          {shortDescription.split(".").map((sentence, index, array) => (
            <React.Fragment key={index}>
              {sentence.trim()}
              {index !== array.length - 1 && "."}
              {index !== array.length - 1 && (
                <>
                  <br /> <br />
                </>
              )}
            </React.Fragment>
          ))}
        </Typography>
        {weblink.map((link) => (
          <Link key={link} href={link} target="_blank">
            <span className="marked">{link}</span>
          </Link>
        ))}
      </Box>
    </Card>
  );
};

const Gallery = () => {
  return (
    <Box
      id="portfolio"
      className="w-full flex flex-col text-center gap-4 p-10 mt-20"
      sx={{
        backgroundImage: "url('./images/gallery_bg.png')",
      }}
    >
      <Typography level="display2">
        Unsere <span className="marked">Projekte</span>
      </Typography>
      <Box className="w-full flex justify-around">
        {projects.map((project) =>
          galleryItem(
            project.title,
            project.shortDescription,
            project.imgUrl,
            project.weblink
          )
        )}
      </Box>
    </Box>
  );
};

export default Gallery;
