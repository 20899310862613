import { Box } from "@mui/joy";
import Thumbnail from "../content/Thumbnail";
import Contact from "../content/Contact";
import Services from "../content/Services";
import Gallery from "../content/Gallery";

const Content = () => {
  return (
    <Box
      className="w-full flex flex-col items-center justify-center"
      sx={{ backgroundColor: "white" }}
    >
      <Thumbnail />
      <Services />
      <Gallery />
      <Contact />
    </Box>
  );
};

export default Content;
