export default async function handleContact(data) {
  const url = "https://63ciejz9v8.execute-api.eu-central-1.amazonaws.com/send";
  const source = "BHNsoft";
  data.source = source;

  await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        console.log("Message sent successfully");
      } else {
        console.log(res);
        console.log("Message failed to send");
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
