import {
  extendTheme as extendJoyTheme,
  shouldSkipGeneratingVar as joyShouldSkipGeneratingVar,
} from "@mui/joy/styles";
import {
  experimental_extendTheme as extendMuiTheme,
  responsiveFontSizes,
  shouldSkipGeneratingVar as muiShouldSkipGeneratingVar,
} from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

const fontFamily = "Montserrat, sans-serif";
const muiTheme = extendMuiTheme({
  typography: {
    fontFamily,
    body: { fontFamily },
  },
});

const joyTheme = extendJoyTheme({
  cssVarPrefix: "mui",
  typography: {
    fontFamily,
  },
});

const mergedTheme = responsiveFontSizes(
  deepmerge(
    {
      ...joyTheme,
      ...muiTheme,
      colorSchemes: deepmerge(joyTheme.colorSchemes, muiTheme.colorSchemes),
      typography: {
        ...joyTheme.typography,
        ...muiTheme.typography,
      },
      zIndex: {
        ...joyTheme.zIndex,
        ...muiTheme.zIndex,
      },
    },
    {
      typography: {
        h4: { lineHeight: 1, fontSize: "2.3rem" },
        body3: { lineHeight: 1, fontSize: "2rem" },
        body4: { lineHeight: 1, fontSize: "2rem" },
        body5: { lineHeight: 1, fontSize: "2rem" },
        display1: { lineHeight: 1, fontSize: "3rem" },
        display2: { lineHeight: 1, fontSize: "2rem" },
        display3: { lineHeight: 1, fontSize: "2rem" },
        display4: { lineHeight: 1, fontSize: "2rem" },
        display5: { lineHeight: 1, fontSize: "2rem" },
      },
    }
  ),
  {
    variants: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "body3",
      "body4",
      "body5",
      "display1",
      "display2",
      "display3",
      "display4",
      "display5",
      "caption",
      "button",
      "overline",
    ],
  }
);

mergedTheme.shouldSkipGeneratingVar = (keys) =>
  joyShouldSkipGeneratingVar(keys) || muiShouldSkipGeneratingVar(keys);
mergedTheme.generateCssVars = (colorScheme) => ({
  css: {
    ...joyTheme.generateCssVars(colorScheme).css,
    ...muiTheme.generateCssVars(colorScheme).css,
  },
  vars: deepmerge(
    joyTheme.generateCssVars(colorScheme).vars,
    muiTheme.generateCssVars(colorScheme).vars
  ),
});
mergedTheme.unstable_sxConfig = {
  ...muiTheme.unstable_sxConfig,
  ...joyTheme.unstable_sxConfig,
};

export default mergedTheme;
