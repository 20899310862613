import { Box, Card, Typography } from "@mui/joy";
import { Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const services = [
  {
    title: "IT-Consulting",
    description:
      "Expertise und Unterstützung für die erfolgreiche Integration von Technologie in Ihr Unternehmen.",
    imgUrl: "/pics/consulting.png",
  },
  {
    title: "Software Development",
    description:
      "Maßgeschneiderte Softwarelösungen für Ihre individuellen Geschäftsanforderungen.",
    imgUrl: "/pics/backend_smartart.png",
  },
  {
    title: "Web Development",
    description:
      "Professionelle Webentwicklung für eine moderne und ansprechende Online-Präsenz Ihres Unternehmens.",
    imgUrl: "/pics/web-development_smartart.png",
  },
];

const serviceCard = (title, description, imgUrl) => {
  return (
    <Card
      key={title}
      className="flex items-center flex-col gap-4"
      variant="outlined"
      // sx={{ width: 320, backgroundColor: "white" }}
      sx={{
        padding: "2rem",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        height: "100%",
      }}
    >
      <Box className="mt-10">
        <img src={imgUrl} className="h-32" loading="lazy" alt="" />
      </Box>
      <Box className="flex flex-col justify-center p-5 gap-4 items-center">
        <Typography
          level="h5"
          textAlign="center"
          sx={{ fontSize: "md", mt: 3 }}
          fontWeight="var(--font-weight-heading)"
          letterSpacing={1}
        >
          <span className="marked">{title}</span>
        </Typography>
        <Typography
          level="h6"
          textAlign="center"
          fontWeight="var(--font-weight-light)"
          letterSpacing={0.5}
          sx={{ mt: 2, mb: 2 }}
        >
          {description}
        </Typography>
      </Box>
    </Card>
  );
};

const Services = () => {
  return (
    <Box
      id="services"
      className="w-full flex flex-col text-center gap-4 p-10 "
      sx={{
        maxWidth: "var(--content-width)",
      }}
    >
      <Typography level="display2">
        Unsere <span className="marked">Services</span>
      </Typography>
      <Box
        className="flex justify-center"
        sx={{ "--swiper-navigation-size": { xs: "2rem", md: "3rem" } }}
      >
        <Swiper
          className="mySwiper"
          slidesPerView={1}
          spaceBetween={20}
          pagination={{ clickable: true }}
          navigation
          // scrollbar={{ draggable: true }}
          modules={[Pagination, Navigation, Scrollbar]}
          centerInsufficientSlides
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 20 },
            900: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {services.map((service, i) => (
            <SwiperSlide key={i} style={{}}>
              {serviceCard(service.title, service.description, service.imgUrl)}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Services;
