import { Box, Button, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

import {
  // FaFacebookF,
  // FaInstagram,
  // FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { CiMapPin } from "react-icons/ci";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { useMediaQuery } from "@mui/material";

const anchors = [
  { href: "/legal", name: "Impressum" },
  { href: "/privacy", name: "Datenschutz" },
];

const socials = [
  // { href: "/facebook", name: "Facebook", icon: <FaFacebookF /> },
  // { href: "/instagram", name: "Instagram", icon: <FaInstagram /> },
  // { href: "/twitter", name: "Twitter", icon: <FaTwitter /> },
  {
    href: "https://www.linkedin.com/company/bhnsoft",
    name: "LinkedIn",
    icon: <FaLinkedin />,
  },
];

const MenuLink = ({ name, href }) => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Link to={href} className="flex items-center">
      <Button
        variant="plain"
        className="flex items-center justify-center"
        sx={{
          color: "white",
          my: { xs: 0.5, md: 2 },
          fontSize: "1rem",
          ":hover": {
            backgroundColor: "unset",
            textDecoration: "underline",
          },
        }}
        onClick={goToTop}
      >
        <span>{name}</span>
      </Button>
    </Link>
  );
};

const Footer = () => {
  return (
    <footer
      className="w-full justify-center"
      style={{
        backgroundColor: "var(--footer-background-color)",
      }}
    >
      <Box
        className="mx-auto flex flex-col p-3 pt-5 mt-10"
        sx={{
          maxWidth: "var(--content-width)",
        }}
      >
        <Box className="grid md:grid-cols-3 xs:grid-cols-1 gap-4">
          <Link
            to="/"
            className="flex"
            style={{
              justifyContent: useMediaQuery("(max-width: 768px)")
                ? "center"
                : "flex-start",
            }}
          >
            <img
              src="/logo/512x512-01.png"
              alt="logo"
              loading="lazy"
              className={useMediaQuery("(max-width: 600px)") ? "h-16" : "h-32"}
            />
          </Link>
          <nav className="flex justify-center flex-wrap">
            {anchors.map((anchor) => (
              <MenuLink
                key={anchor.name}
                name={anchor.name}
                href={anchor.href}
              />
            ))}
          </nav>
          <Box
            className="flex gap-2 items-center"
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            {socials.map((social) => (
              <a href={social.href} key={social.name}>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(to right, var(--gradient-color1), var(--gradient-color2))",
                    borderRadius: "50%",
                    height: "3rem",
                    width: "3rem",
                    transition: "all 0.2s !important",
                    ":hover": {
                      backgroundImage: "unset",
                      background: "white",
                      color: "var(--gradient-color2)",
                      transform: "scale(1.2)",
                    },
                  }}
                >
                  {social.icon}
                </Button>
              </a>
            ))}
          </Box>
        </Box>
        <Box className="grid xs:grid-cols-1 md:grid-cols-3 mt-8 mb-8 gap-4">
          <Box className="mx-auto md:ml-0">
            <Box className="flex items-center gap-5">
              <CiMapPin style={{ fill: "white" }} />
              <Box className="flex flex-col">
                <Typography textColor="white" level="body1">
                  Gasteigerstraße 21c
                </Typography>
                <Typography textColor="white" level="body1">
                  6380 St. Johann i. T.
                </Typography>
                <Typography textColor="white" level="body1">
                  Österreich
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="flex md:mx-auto gap-5 items-center justify-center">
            <AiOutlineMail style={{ fill: "white" }} />
            <Typography level="body1" sx={{ color: "white" }}>
              <a className="hover:underline" href="mailto:office@bhn-soft.at">
                office@bhn-soft.at
              </a>
            </Typography>
          </Box>
          <Box className="md:ml-auto flex gap-5 items-center justify-center">
            <AiOutlinePhone style={{ fill: "white" }} />
            <Typography level="body1" sx={{ color: "white" }}>
              <a className="hover:underline" href="tel:+436605767679">
                +43 660 576 767 9
              </a>
            </Typography>
          </Box>
        </Box>
        <Box className="w-full flex items-center justify-center mt-5 mb-4">
          <Typography
            level="body2"
            textColor="white"
            marginBottom="1rem"
            fontSize={12}
          >
            © 2023 BHNsoft GesbR - All rights reserved
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
