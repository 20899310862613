import { Box, Typography } from "@mui/joy";

const Thumbnail = () => {
  return (
    <Box
      className="w-full flex flex-col items-center justify-center"
      sx={{
        backgroundImage: "url('/pics/thumbnail_background.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "45rem",
        backgroundPosition: "center",
        // marginTop: "-6rem",
      }}
      //xs:h-12 = 3rem md:h-32 = 8rem
    >
      <Box
        className="container text-center"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box className="w-full flex flex-col justify-center">
          <Box>
            <Typography
              level="display1"
              textColor="white"
              fontWeight="var(--font-weight-heading)"
              letterSpacing="0.1rem"
              sx={{
                fontSize: {
                  lg: 80,
                  md: 60,
                  sm: 40,
                  xs: 25,
                },
              }}
            >
              Professionelle Software
            </Typography>
            <Typography
              level="display1"
              textColor="white"
              fontWeight="var(--font-weight-heading)"
              letterSpacing="0.1rem"
              sx={{
                fontSize: {
                  lg: 80,
                  md: 60,
                  sm: 40,
                  xs: 25,
                },
              }}
            >
              Made in <span className="marked">Austria</span>
            </Typography>
          </Box>
          <Box className="mt-3">
            <Typography
              level="h4"
              textColor="white"
              fontWeight="var(--font-weight-light)"
            >
              Wir erstellen für Sie maßgeschneiderte Softwarelösungen,
            </Typography>
            <Typography
              level="h4"
              textColor="white"
              fontWeight="var(--font-weight-light)"
            >
              welche Ihre Prozesse optimieren und Ihre Mitarbeiter unterstützen.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Thumbnail;
