import {
  Experimental_CssVarsProvider as CssVarsProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import Content from "./components/layout/Content";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";

import Legal from "./components/pages/Legal";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import mergedTheme from "./theme";

const App = () => {
  return (
    <CssVarsProvider theme={responsiveFontSizes(mergedTheme)}>
      <div className="w-full flex flex-col" style={{ minHeight: "100vh" }}>
        <Router>
          <Header />
          <Routes>
            <Route path="/*" element={<Content />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/legal" element={<Legal />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </CssVarsProvider>
  );
};

export default App;
