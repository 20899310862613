import { Box, Button } from "@mui/joy";
import { Drawer, useMediaQuery } from "@mui/material";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import {
  ArrowForwardIos as ArrowForwardIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from "@mui/icons-material";

const anchors = [
  { href: "#services", name: "Services" },
  { href: "#portfolio", name: "Portfolio" },
];

const MenuLink = ({ href, name }) => {
  const location = useLocation();
  const button = (
    <Button
      variant="plain"
      onClick={() => {
        const element = document.querySelector(href);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }}
      className="flex justify-center items-center"
      sx={{
        color: location.pathname !== "/" ? "black" : "white",
        fontSize: "1.2rem",
        ":hover": {
          backgroundColor: "unset",
          textDecoration: "underline",
        },
        letterSpacing: "0.1rem",
      }}
    >
      {name}
    </Button>
  );

  return (
    <Box className="flex items-center">
      <Box className="flex">{button}</Box>
    </Box>
  );
};

const Header = () => {
  const location = useLocation();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <header
      className="w-full sticky flex justify-center"
      style={{
        background: location.pathname !== "/" ? "white" : "",
        height: location.pathname !== "/" ? "" : "0px",
      }}
    >
      <Box
        className="xs:h-12 md:h-32 w-full grid grid-cols-3 py-0 px-2"
        sx={{
          maxWidth: "var(--content-width)",
          paddingTop: location.pathname !== "/" ? "0" : "1rem",
          paddingBottom: location.pathname !== "/" ? "0" : "1rem",
        }}
      >
        <Box
          className="sm:hidden p-1 flex"
          sx={{ color: location.pathname !== "/" ? "black" : "white" }}
          onClick={() => setMobileNavOpen((mobileNavOpen) => !mobileNavOpen)}
        >
          <Box className="h-full aspect-square flex items-center justify-center">
            {mobileNavOpen ? (
              <MenuOpenIcon sx={{ height: "2rem", width: "auto" }} />
            ) : (
              <MenuIcon sx={{ height: "2rem", width: "auto" }} />
            )}
          </Box>
        </Box>
        <Drawer
          open={mobileNavOpen}
          onClose={() => setMobileNavOpen(false)}
          className="h-full"
        >
          <Box
            className="w-full flex flex-col gap-2 p-2 h-full"
            sx={{ backgroundColor: "var(--bg-color)" }}
          >
            <Box className="flex text-white justify-end">
              <CloseIcon
                onClick={() => setMobileNavOpen(false)}
                sx={{ height: "2rem", width: "auto" }}
              />
            </Box>
            {anchors.map(({ name, href }) => (
              <Box
                className="flex gap-2 text-white items-center pl-2"
                key={name}
              >
                <ArrowForwardIcon sx={{ height: "2rem" }} />
                <Button
                  variant="plain"
                  component="a"
                  href={href}
                  className="flex items-center flex-grow"
                  onClick={() => setMobileNavOpen(false)}
                  sx={{
                    color: "white",
                    fontSize: "1rem",
                    ":hover": {
                      backgroundColor: "unset",
                      textDecoration: "underline",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <span>{name}</span>
                </Button>
              </Box>
            ))}
          </Box>
        </Drawer>
        <Box className="h-full flex items-center">
          <Link
            to="/"
            className="w-full flex xs:justify-center md:justify-start"
          >
            <img
              alt="logo"
              src={
                location.pathname !== "/"
                  ? "/logo/BHN-Logo-01.png"
                  : "/logo/BHN-Logo-02.png"
              }
              loading="lazy"
              className={
                useMediaQuery("(max-width: 600px)")
                  ? "h-16"
                  : location.pathname !== "/"
                  ? "h-24"
                  : "h-32"
              }
            />
          </Link>
        </Box>
        <nav className="hidden sm:flex justify-center gap-2 md:gap-3">
          {anchors.map((anchor) => (
            <MenuLink key={anchor.name} {...anchor} />
          ))}
        </nav>
        <Box className="xs:ml-auto flex items-center justify-end mr-6 md:mr-0">
          <Button
            className="button--round"
            component="a"
            onClick={() => {
              const element = document.querySelector("#contact");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <AiOutlineMail style={{ transform: "scaleX(-1)" }} color="white" />
            {useMediaQuery("(min-width: 950px)") && "Kontaktiere uns"}
          </Button>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
