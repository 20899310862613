import React from "react";
import { Box, Card, Typography } from "@mui/joy";

import Link from "@mui/material/Link";

const PrivacyPolicy = () => {
  return (
    <Box
      className="w-full flex flex-col items-center justify-center flex-grow"
      sx={{ backgroundColor: "#F0F0F0" }}
    >
      <Card className="bg-white container m-10">
        <Typography
          level="h4"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginBottom="1rem"
          color="#232023"
          fontWeight="bold"
        >
          Erklärung zur Datenverarbeitung
        </Typography>
        <Typography level="body1">
          In folgender Datenschutzerklärung informieren wir Sie über die
          wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Webseite.
          Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage
          der gesetzlichen Bestimmungen (Datenschutzgrundverordnung,
          Telekommunikationsgesetz 2003). Sobald Sie als Benutzer auf unsere
          Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, Beginn
          sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt
          und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f
          DSGVO.
        </Typography>
        <Typography
          level="h6"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          marginBottom="0.5rem"
          color="#232023"
        >
          Kontakt mit uns
        </Typography>
        <Typography level="body1">
          Wenn Sie uns, entweder über unser Kontaktformular auf unserer
          Webseite, oder per Email kontaktieren, dann werden die von Ihnen an
          uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den
          Fall von weiteren Anschlussfragen für sechs Monate bei uns
          gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe
          Ihrer übermittelten Daten.
        </Typography>
        <Typography
          level="h6"
          variant="solid"
          bgcolor="transparent"
          fontFamily="Montserrat"
          marginTop="1rem"
          marginBottom="0.5rem"
          color="#232023"
        >
          Datenspeicherung
        </Typography>
        <Typography level="body1" marginBottom="0.5rem">
          Im Rahmen der Erleichterung des Einkaufsvorganges und zur späteren
          Vertragsabwicklung werden vom Webshop-Betreiber im Rahmen von Cookies
          die IP-Adresse des Anschlussinhabers gespeichert, ebenso wie Name,
          Anschrift, Zahlungsinformationen, Email.
        </Typography>
        <Typography level="body1" marginBottom="0.5rem">
          Für die Vertragsabwicklung werden auch folgende Daten bei uns
          gespeichert: Email, Telefonnummer, Name, Anschrift.
        </Typography>
        <Typography level="body1" marginBottom="0.5rem">
          Die von Ihnen bereitgestellten Daten sind für die Vertragserfüllung
          und zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ein
          Vertragsabschluss ohne dieser Daten ist nicht möglich. Eine
          Übermittlung der erhobenen Daten an Dritte erfolgt nicht, mit Ausnahme
          der Übermittlung von Zahlungsdaten (Kreditkartendaten) an die
          abwickelnden Bankinstitute/Zahlungsdienstleister zum Zwecke der
          Abbuchung des Einkaufspreises, an das von uns Beauftragte
          Versandunternehmen (Transportunternehmen), welches mit der Zustellung
          der Ware beauftragt ist und unseren Steuerberater zur Erfüllung
          unserer steuerrechtlichen Verpflichtungen.
        </Typography>
        <Typography level="body1" marginBottom="1rem">
          Sollten Sie den Einkaufsvorgang abbrechen, werden diese bei uns
          gespeicherten Daten gelöscht. Soll ein Vertragsabschluss zustande
          kommen, werden sämtliche Daten, resultierend aus dem
          Vertragsverhältnis, bis zum Ablauf der steuerrechtlichen
          Aufbewahrungsfirst (7 Jahre) gespeichert. Der übermittelte Name, die
          Anschrift, gekaufte Waren und Kaufdatum werden darüber hinaus bis zum
          Ablauf der Produkthaftung (10 Jahre) gespeichert.
        </Typography>
        <Typography level="caption">Stand: 01.01.2023</Typography>
        <Typography level="caption">
          Quelle:{" "}
          <Link
            href="https://rechtstexte-generator.at"
            underline="hover"
            color="black"
          >
            Datenschutz DSVGO Generator
          </Link>
        </Typography>
      </Card>
    </Box>
  );
};

export default PrivacyPolicy;
